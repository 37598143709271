<template>
  <div v-cloak v-if="apiLoaded">
    <template>
      <div class="block">
        <div class="list">
          <div v-for="(report) in processMonitorConfig.reports"
               :key="'reportIndex_' + report.id"
               class="list-item-wrap">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="report-list">
                    <div class="report-list__item">
                      <div class="report-list__title">
                        <button class="toggleSwitch">
                          <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                               width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
                          </svg>
                          <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                               width="24"
                               xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
                          </svg>
                        </button>
                        <span>{{ report.name }}</span>
                      </div>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <table>
                    <thead>
                    <tr>
                      <th>Краткое название показателя</th>
                      <th>
                        <span>Значение
                            <v-dialog
                                v-model="paramsDialog"
                                max-width="1150px"
                                persistent>
                                <template v-slot:activator="{ on }">
                                    <div v-on="on">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18.13 12L19.39 10.74C19.83 10.3 20.39 10.06 21 10V9L15 3H5C3.89 3 3 3.89 3 5V19C3 20.1 3.89 21 5 21H11V19.13L11.13 19H5V5H12V12H18.13ZM14 4.5L19.5 10H14V4.5ZM19.13 13.83L21.17 15.87L15.04 22H13V19.96L19.13 13.83ZM22.85 14.19L21.87 15.17L19.83 13.13L20.81 12.15C21 11.95 21.33 11.95 21.53 12.15L22.85 13.47C23.05 13.67 23.05 14 22.85 14.19Z"
                                            fill="#0033A0"/>
                                        </svg>
                                    </div>
                                </template>

                                <ProcessMonitorParamsDialog
                                    :report-id="report.id"
                                    :group="report.name"
                                    :reportItems="report.indicators"
                                    :values="values"
                                    :period="period"
                                    :region-and-department-info="regionAndDepartmentInfo"
                                    @close="paramsDialog=false;"
                                    v-if="paramsDialog"
                                    @edit="saveParams(); paramsDialog=false;"/>
                            </v-dialog>
                        </span>
                      </th>
                      <!--<th>Размерность</th>-->
                    </tr>
                    </thead>
                    <tbody>
                    <template>
                      <tr v-for="(reportItem, indexItem) in report.indicators"
                          :key="'indexItem' + indexItem">
                        <td>
                          {{ reportItem.name }}
                        </td>
                        <td>
                          {{ getValue(report.id, reportItem.id)?.value || '-' }}
                        </td>
                        <td>
                          {{ reportItem.unit }}
                        </td>
                      </tr>
                    </template>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
      </div>
    </template>

  </div>
</template>

<script>
import ProcessMonitorParamsDialog from "@/components/dialogs/ProcessMonitorParamsDialog";
import {processMonitorConfig} from "@/modules/ProcessMonitorConfig";

export default {
  name: "ProcessMonitorEcpReports",
  props: ['regionAndDepartmentInfo', 'period'],
  components: {
    ProcessMonitorParamsDialog,
  },
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        //console.log(this.regionAndDepartmentInfo)
        this.apiLoaded = false;
        await this.loadParams();
        this.$forceUpdate();
        this.apiLoaded = true;
      },
      deep: true
    },
    period: {
      async handler() {
        this.apiLoaded = false;
        await this.loadParams();
        this.$forceUpdate();
        this.apiLoaded = true;
      }
    }
  },

  data: () => ({
    processMonitorConfig: processMonitorConfig,
    paramsDialog: false,
    apiLoaded: false,
    values: []
  }),

  filters: {},

  methods: {
    async downloadReport() {
      this.showLoaderDialog = true;

      let query = {
        headerCells: [
          {
            caption: "Показатель",
            width: 10000,
          },
          {
            caption: "Значение",
            width: 10000,
          },
          {
            caption: "Единица измерения",
            width: 10000,
          }
        ],
        rows: []
      }
      query.rows = []
      for (let e of this.reportItems) {
        query.rows.push([
          {cellDataType: "STRING", value: e.title},
          {cellDataType: "STRING", value: e.value},
          {cellDataType: "STRING", value: e.dimensions}
        ])
      }
      await this.$downloadApi("Отчет.xlsx", "/export/xlsx", query, true);
      this.showLoaderDialog = false;
    },

    getValue(reportId, indicatorId) {
      return this.values.find(
          e => e.reportId === reportId
              && e.indicatorId === indicatorId
              && e.period === this.period
              && e.regionCode === this.regionAndDepartmentInfo.region.code
              && e.departmentId == this.regionAndDepartmentInfo.department?.id)
    },

    // async notifyUser(){
    //   await api.postJson("/notifs/notifyVniiUploaded")
    // },

    loadParams() {
      let paramsJson = localStorage.getItem('processMonreportItems')
      if (paramsJson) {
        this.values = JSON.parse(paramsJson)
      } else {
        this.values = []
      }
    },

    saveParams() {
      //localStorage.setItem('processMonreportItems', [])
      localStorage.setItem('processMonreportItems', JSON.stringify(this.values))
    },
  },

  async beforeMount() {
    this.loadParams()
    this.apiLoaded = true;
    this.show = true;
    this.expandedPanels = [];

  }
}
</script>

<style lang="scss">
@import "../../styles/main";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

.list {
  .list-item-wrap {
    padding: 5px 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
