<template>
  <div v-cloak v-if="apiLoaded">
     <div class="block">
        <h3>Отчёты по уровню соответствия фактических значений нормативным</h3>
        <div class="list">
          <div class="report-list">
            <ResultMonitorReport
                v-for="(report, reportIndex) in normativeReports"
                :key = "'federal_report_'+reportIndex"
                :title="(reportIndex+1) + ' ' + report.name"
                :period="period"
                :report-data-function="report.dataFunction"
                :region-and-department-info="regionAndDepartmentInfo"
            ></ResultMonitorReport>
          </div>
        </div>

        <h3>Типовые отчёты</h3>
        <div class="list">
          <div class="report-list">
            <ResultMonitorReport
                v-for="(report, reportIndex) in typeReports"
                :key = "'regional_report_'+reportIndex"
                :title="(reportIndex+1) + ' ' + report.name"
                :period="period"
                :report-data-function="report.dataFunction"
                :region-and-department-info="regionAndDepartmentInfo"
            ></ResultMonitorReport>
          </div>
        </div>
     </div>
  </div>
</template>

<script>

import ResultMonitorReport from "@/components/resultmonitor_new/ResultMonitorReport";
import {getNormativeReport, getSvodReport, setDepartmentsByRegion} from "@/modules/ProcessMonitor";
export default {
  name: "ProcessMonitorReports",
  components: {
    ResultMonitorReport
  },
  props: ['regionAndDepartmentInfo', 'period'],
  watch: {},

  data: () => ({
    normativeReports: [
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги содействия гражданам в поиске подходящей работы',
        dataFunction: getNormativeReport.bind(null, 1)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги содействия работодателям в подборе необходимых работников',
        dataFunction: getNormativeReport.bind(null, 2)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги по организации временного трудоустройства несовершеннолетних граждан в возрасте от 14 до 18 лет в свободное от учебы время, безработных граждан, испытывающих трудности в поиске работы, безработных граждан в возрасте от 18 до 25 лет, имеющих среднее профессиональное образование или высшее образование и ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации',
        dataFunction: getNormativeReport.bind(null, 8)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при организация проведения оплачиваемых общественных работ',
        dataFunction: getNormativeReport.bind(null, 7)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги по организации профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности',
        dataFunction: getNormativeReport.bind(null, 4)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги по организации профессиональной ориентации граждан в целях выбора сферы деятельности (профессии), трудоустройства, прохождения профессионального обучения и получения дополнительного профессионального образования',
        dataFunction: getNormativeReport.bind(null, 3)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги по организации сопровождения при содействии занятости инвалидов',
        dataFunction: getNormativeReport.bind(null, 9)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при организации профессионального обучения и дополнительного профессионального образования женщин в период отпуска по уходу за ребенком до достижения им возраста трех лет, незанятых граждан, которым в соответствии с законодательством Российской Федерации назначена страховая пенсия по старости и которые стремятся возобновить трудовую деятельность',
        dataFunction: getNormativeReport.bind(null, 5)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги по социальной адаптации безработных граждан на рынке труда',
        dataFunction: getNormativeReport.bind(null, 10)
      },
      {
        name: 'Отчет по уровню соответствия фактических значений нормативным при выполнении административных процедур (действий) при оказании государственной услуги по психологической поддержке безработных граждан',
        dataFunction: getNormativeReport.bind(null, 11)
      }
    ],

    typeReports: [
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги содействия гражданам в поиске подходящей работы',
        dataFunction: getSvodReport.bind(null, 1)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги содействия работодателям в подборе необходимых работников',
        dataFunction: getSvodReport.bind(null, 2)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации профессиональной ориентации граждан в целях выбора сферы деятельности (профессии), трудоустройства, прохождения профессионального обучения и получения дополнительного профессионального образования',
        dataFunction: getSvodReport.bind(null, 3)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации профессионального обучения и дополнительного профессионального образования безработных граждан, включая обучение в другой местности',
        dataFunction: getSvodReport.bind(null, 4)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению полномочий по организации профессионального обучения и дополнительного профессионального образования женщин в период отпуска по уходу за ребенком до достижения им возраста трех лет, незанятых граждан, которым в соответствии с законодательством Российской Федерации назначена страховая пенсия по старости и которые стремятся возобновить трудовую деятельность',
        dataFunction: getSvodReport.bind(null, 5)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению полномочий по организации и проведению специальных мероприятий по профилированию граждан, зарегистрированных в целях поиска подходящей работы, и работодателей',
        dataFunction: getSvodReport.bind(null, 6)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению полномочий по организации проведения оплачиваемых общественных работ',
        dataFunction: getSvodReport.bind(null, 7)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации временного трудоустройства несовершеннолетних граждан в возрасте от 14 до 18 лет в свободное от учебы время, безработных граждан, испытывающих трудности в поиске работы, безработных граждан в возрасте от 18 до 25 лет, имеющих среднее профессиональное образование или высшее образование и ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации',
        dataFunction: getSvodReport.bind(null, 8)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации сопровождения при содействии занятости инвалидов',
        dataFunction: getSvodReport.bind(null, 9)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по социальной адаптации безработных граждан на рынке труда',
        dataFunction: getSvodReport.bind(null, 10)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по психологической поддержке безработных граждан',
        dataFunction: getSvodReport.bind(null, 11)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по содействию безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости',
        dataFunction: getSvodReport.bind(null, 12)
      },
      {
        name: 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по содействию осуществления предпринимательской деятельности безработных граждан, включая оказание гражданам, признанным в установленном порядке безработными, и гражданам, признанным в установленном порядке безработными и прошедшим профессиональное обучение или получившим дополнительное профессиональное образование по направлению органов службы занятости, единовременной финансовой помощи при государственной регистрации в качестве индивидуального предпринимателя, государственной регистрации создаваемого юридического лица, государственной регистрации крестьянского (фермерского) хозяйства, постановке на учет физического лица в качестве налогоплательщика налога на профессиональный доход',
        dataFunction: getSvodReport.bind(null, 13)
      }
    ],

    showLoaderDialog: false,
    paramsDialog: false,
    cznIccData: [], //JSON.parse(cznIccDataString),
    regionIccData: [], //JSON.parse(regionIccDataString),
    soiskAnswers: [], //JSON.parse(anketSoiskString),
    rabAnswers: [], //JSON.parse(anketRabString),
    iccDataChecklists: [], // JSON.parse(iccDataChecklistsString),
    cznSoiskAnswers: [],
    cznRabAnswers: [],
    checklistValues: [],
    checklistsConfig: null,
    monitoringLevel: 'federal',
    dateRangeOptions: {},

    apiLoaded: false,
    show: false,
    maxDate: new Date().toJSON().slice(0, 10),
    paramsDialogKey: 0,

    dashboardExportDialog: false,

    expandedPanels: [0, 1, 2, 3, 4, 5, 6, 7, 8],

    currentQuarter: null,

    regionView: false,

    config: null,
    configId: null,

    formData: null,
    parametersTitleMap: new Map(),
    parametersAnswerStatusesPartMap: new Map(),
    parametersTextPartMap: new Map(),

    componentKey: 0,

    startDateMenu: false,
    endDateMenu: false,

    dynamicStartDate: null,
    dynamicEndDate: null,

    reports: [
      {
        id: 2,
        title: 'Отчет по отдельному ЦЗН по показателям результативности оказания государственных услуг, содержащий сведения, на основе которых формируются показатели и значения показателей',
      },
      {
        id: 3,
        title: 'Сводный отчет по ЦЗН субъекта Российской Федерации по показателям результативности оказания государственных услуг, содержащий значения указанных показателей в разрезе ЦЗН субъекта Российской Федерации и агрегированные значения по совокупности ЦЗН субъекта Российской Федерации',
      },

    ],
  }),

  methods: {
  },

  async beforeMount() {
    setDepartmentsByRegion(this.$cznDepartmentsByRegionList)
    this.apiLoaded = true;
    this.show = true;
    this.expandedPanels = [];

  }
}
</script>

<style lang="scss">
@import "../../styles/main.scss";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.line-wrap {
  border: 1px solid #E8E8E9;
  border-radius: 4px;
}

.list {
  .list-item-wrap {
    padding: 0;
  }

  table {
    margin-bottom: 25px;

    th {
      padding: 5px 20px 5px 0;

      div.v-dialog__container + div {
        height: 24px;
        cursor: pointer;
      }

      span {
        display: flex;
        justify-content: flex-start;
        gap: 3px;
        min-width: min(150px, 20vw);
      }

      &:first-child {
        text-align: center;
        width: 100%;
      }
    }

    td {
      padding: 5px 20px 5px 0;
      border-top: 1px solid #E8E8E9;
      border-bottom: 0;

      & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;

        svg {
          margin-right: 8px;
          flex-shrink: 0;
          margin-top: 5px;
          width: 20px;
        }

        a {
          white-space: nowrap;
        }
      }
    }

    tr:last-child {
      td {
        border-top: 1px solid #E8E8E9;
      }
    }
  }
}

.report-list {
  border-top: 1px solid #e8e8e9;

  .report-list__item {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #e8e8e9;
    padding: 5px 0;

    .report-list__title {
      .toggleSwitch {
        margin-right: 5px;
      }

      span {
        color: $cblue;
      }

      display: flex;
      flex-flow: row nowrap;
      align-items: center;

    }

    .report-list__download {
      background: url(~@/assets/download.svg) no-repeat center center;
      background-size: contain;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

.v-menu__content.menuable__content__active {
  box-shadow: none;
  border: 1px solid #A0A0A0;
  border-radius: 0;

  .downloads {
    border-radius: 0;

    .v-list {
      padding: 0;
      background: #E9E9E9;

      .v-list-item {
        padding: 0;
        min-height: initial;

        .v-list-item__title {
          padding: 4px 8px;
          color: $cblue;
        }
      }
    }
  }
}

</style>
