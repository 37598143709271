<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">

      <div class="wrap clientindex">
        <div class="monitor-header">
          <h1>Мониторинг исполнения стандартов процессов осуществления органами службы занятости полномочий в области
            содействия занятости населения</h1>
        </div>
        <div class="monitor-filter">
          <RegionAndDepartmentPicker v-model="regionAndDepartmentInfo" nullable-department="true"></RegionAndDepartmentPicker>

          <div class="passport-buttons">
            <v-dialog
                v-model="dialog"
                max-width="800px"
                persistent>
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <v-btn outlined>
                    документы
                  </v-btn>
                </div>
              </template>
              <PassportInfoDocsDialog
                  :region="regionAndDepartmentInfo.region"
                  :departments="regionAndDepartmentInfo.region?.departments"
                  section="Мониторинг исполнения стандарта процессов"
                  @close="dialog=false; dialogKey++" :key="dialogKey"
                  @edit="dialog=false; dialogUpload=true;"/>
            </v-dialog>
            <v-btn outlined @click="$router.push('/orgmonitor/archive')"
                   v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'">
              архив<br>документов
            </v-btn>
            <v-dialog
                v-model="dialogUpload"
                max-width="800px"
                persistent>
              <PassportInfoDocsUploadDialog
                  :region="regionAndDepartmentInfo.region"
                  :departments="regionAndDepartmentInfo.region?.department"
                  section="Мониторинг исполнения стандарта процессов"
                  :categories="passportConfig?.documentCategories"
                  @close="dialogUpload=false; dialogKey++"
                  :key="dialogKey"/>
            </v-dialog>
          </div>
        </div>
        <div class="block">
          <v-select style="width:400px"
                    :items="periods"
                    v-model="period"
                    label="Период мониторинга"
          ></v-select>
        </div>
        <v-tabs>
          <v-tab>Отчёты ЕЦП</v-tab>
          <v-tab>Типовые отчёты</v-tab>
          <v-tab>Рейтинги</v-tab>

          <v-tab-item>
            <ProcessMonitorEcpReports
                :region-and-department-info="regionAndDepartmentInfo" :period="period"></ProcessMonitorEcpReports>
          </v-tab-item>

          <v-tab-item>
            <ProcessMonitorReports
                :region-and-department-info="regionAndDepartmentInfo" :period="period"></ProcessMonitorReports>
          </v-tab-item>

          <v-tab-item>
            <ProcessMonitorRating
                :region-and-department-info="regionAndDepartmentInfo" :period="period"></ProcessMonitorRating>
          </v-tab-item>
        </v-tabs>
      </div>
    </transition>
  </div>
</template>

<script>

import ProcessMonitorEcpReports from "@/components/processmonitor/ProcessMonitorEcpReports";
import ProcessMonitorRating from "@/components/processmonitor/ProcessMonitorRating";
import PassportInfoDocsDialog from "@/components/dialogs/PassportInfoDocsDialog";
import PassportInfoDocsUploadDialog from "@/components/dialogs/PassportInfoDocsUploadDialog";
import RegionAndDepartmentPicker from "@/components/RegionAndDepartmentPicker";
import ProcessMonitorReports from "@/components/processmonitor/ProcessMonitorReports";


export default {
  name: 'ProcessMonitorComponent',
  components: {
    ProcessMonitorEcpReports,
    ProcessMonitorReports,
    ProcessMonitorRating,
    PassportInfoDocsDialog,
    PassportInfoDocsUploadDialog,
    RegionAndDepartmentPicker
  },

  data: () => ({
    periods: [
      {
        text: '1 января 2022 - 31 декабря 2022',
        value: 3
      },
      {
        text: '1 января 2021 - 31 декабря 2021',
        value: 1
      }
    ],
    period: 3,
    regionAndDepartmentInfo: {region: null, department: null},
    checkListExportDialog: false,
    exportDialogKey: 0,
    dialog: false,
    dialogUpload: false,
    dialogKey: 0,
    passportConfig: {}
  }),

  methods: {

    // нужен по сути только чтобы загрузить секции документов из конфига паспорт региона
    async loadPassportConfig() {
      let req = await this.$getApi("/passport/getActiveConfig");
      if (req.ok) {
        this.passportConfig = req.payload;
        console.log(this.passportConfig)
        this.passportConfig.documentCategories = JSON.parse(this.passportConfig.documentCategoriesJson);
      } else {
        this.errorText =
            "Ошибка подключения к серверу или обработки запроса: " + req.error;
      }
    },
  },

  async beforeMount() {
    await this.loadPassportConfig()
  }
}
</script>

<style lang="scss">
@import "../../styles/main";

.top-progress {
  margin-bottom: 30px;
  display: flex;
  align-items: center;

  > div > div {
    display: flex;
    flex-direction: column;
    height: 280px;
    border-radius: 20px;
    padding: 30px;

    div {
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
    }

    p {
      margin-top: auto;
      margin-bottom: 0;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 140%;
      color: #1C1C1F;

    }
  }

}

.v-input--radio-group.v-input--radio-group--row {
  margin: 0;
}

.date-switch {
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
  color: #0033A0;
}

.clientindex {
  .v-slide-group__content.v-tabs-bar__content {
    justify-content: stretch !important;

    .v-tab {
      flex: 1 1 50%;
      max-width: initial;
    }
  }

  .v-window-item {
    padding-top: 0px;
  }

  .v-expansion-panel .level2 {
    padding-top: 5px;

    .v-expansion-panel-content {
      padding-left: 44px;
    }
  }

  .v-expansion-panel-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 160%;
    color: #1C1C1F;
    margin: 10px 0;
  }

  .list .list-item-wrap .list-item-name {
    max-width: none;

    .toggleSwitch {
      align-self: center;
    }
  }

  .list .list-item-wrap .list-item {
    justify-content: flex-start;

    .v-progress-circular {
      margin-left: 0;

      .v-progress-circular__info {
        font-size: 16px;
        font-weight: 600;
        display: block;

        .text-caption {
          font-size: 10px !important;
          vertical-align: baseline;
          display: inline;
        }
      }
    }
  }

  .list-item-wrap .list-item-wrap {
    border: none;
    padding-bottom: 5px;
  }
}

.v-tabs {
  .v-tabs-bar {
    margin: 0 0 50px 0;
  }
}

.monitor-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 20px;
  position: relative;

  h1 {
    flex: 1 1 100%;
  }

  .download-menu {
    top: 10px;
    position: relative;
    padding-left: 0;
    margin-top: 0;
    flex: 0 0 56px;
  }
}
</style>
