export const processMonitorConfig = {'reports': [{'id': 1.0,
        'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги содействия гражданам в поиске подходящей работы',
        'indicators': [{'id': 1,
            'name': 'Средняя продолжительность поиска работы граждан, подавших заявление',
            'short_name': 'Средняя продолжительность поиска работы (01)',
            'unit': 'Дни'},
            {'id': 2,
                'name': 'Доля граждан, трудоустроенных в течение 10 дней со дня подачи заявления, от общего количества граждан, которые подали заявление',
                'short_name': 'Доля трудоустроенных в течение 10 дней со дня подачи заявления (02)',
                'unit': 'Процент'},
            {'id': 3,
                'name': 'Доля нетрудоустроенных граждан в течение шести месяцев со дня подачи заявления, от общего количества граждан, которые подали заявление',
                'short_name': 'Доля нетрудоустроенных в течение 6 месяцев (03)',
                'unit': 'Процент'},
            {'id': 4,
                'name': 'Средний срок направления гражданам уведомления с перечнем вариантов подходящей работы (вакансий) с момента поступления сведений о свободных рабочих местах и вакантных должностях на единую цифровую платформу',
                'short_name': 'Средний срок направления уведомления с перечнем вариантов подходящей работы (04)',
                'unit': 'Рабочие дни',
                'norm': 10.0},
            {'id': 5,
                'name': 'Средний срок направления гражданам уведомления о проведении переговоров с работодателем с момента получения от заявителя ранжированного перечня вакансий (вариантов подходящей работы)',
                'short_name': 'Средний срок направления уведомления о проведении переговоров (05)',
                'unit': 'Рабочие дни',
                'norm': 10.0},
            {'id': 6,
                'name': 'Доля вакансий, по которым с работодателями назначены переговоры, от общего количества вакансий, по которым направлены уведомления о проведении переговоров с работодателями',
                'short_name': 'Доля вакансий, по которым назначены переговоры (06)',
                'unit': 'Процент'},
            {'id': 7,
                'name': 'Количество граждан, срок трудоустройства которых составляет не более 10 дней со дня подачи заявления',
                'short_name': 'Граждане, трудоустроенные в течение 10 дней',
                'unit': 'Единица'},
            {'id': 8,
                'name': 'Количество граждан, состоящих на регистрационном учете по истечении 6 месяцев с даты подачи заявления',
                'short_name': 'Граждане, не трудоустроенные в течение 6 месяцев',
                'unit': 'Единица'},
            {'id': 9,
                'name': 'Количество принятых заявлений граждан о предоставлении услуги по содействию в поиске подходящей работы',
                'short_name': 'Подано заявлений',
                'unit': 'Единица'},
            {'id': 10,
                'name': 'Количество граждан, признанных безработными',
                'short_name': 'Признано безработными',
                'unit': 'Единица'},
            {'id': 11,
                'name': 'Количество граждан, снятых с регистрационного учета в целях поиска подходящей работы в связи трудоустройством',
                'short_name': 'Снято с учета в связи с трудоустройством',
                'unit': 'Единица'},
            {'id': 12,
                'name': 'Количество безработных граждан, снятых с регистрационного учета в связи с трудоустройством',
                'short_name': 'Снято с учета безработных в связи с трудоустройством',
                'unit': 'Единица'}]},
        {'id': 2.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги содействия работодателям в подборе необходимых работников',
            'indicators': [{'id': 1,
                'name': 'Средний срок подбора необходимых работников по заявлению',
                'short_name': 'Средний срок подбора работников по заявлению (01)',
                'unit': 'Дни',
                'norm': 10.0},
                {'id': 2,
                    'name': 'Средний срок направления работодателю уведомления с перечнем подобранных кандидатур работников с момента подачи заявления',
                    'short_name': 'Средний срок подбора кандидатур с подачи заявления (02)',
                    'unit': 'Рабочие дни',
                    'norm': 10.0},
                {'id': 3,
                    'name': 'Доля заявлений, по которым было принято решение о прекращении предоставления государственной услуги при длительном отсутствии взаимодействия работодателя с центром занятости населения',
                    'short_name': 'Доля прекращенных заявлений при длительном отсутствии контакта с ЦЗН (03)',
                    'unit': 'Процент'},
                {'id': 4,
                    'name': 'Доля заявлений, по которым государственная услуга прекращена в связи с отказом работодателя от посредничества центра занятости населения',
                    'short_name': 'Доля прекращенных заявления из-за отказа от услуг ЦЗН (04)',
                    'unit': 'Процент'},
                {'id': 5,
                    'name': 'Количество заявлений, поданных в отчетном периоде',
                    'short_name': 'Всего заявлений в отчетном периоде',
                    'unit': 'Единица'},
                {'id': 6,
                    'name': 'Количество заявлений, предоставление государственной услуги по которым прекращено',
                    'short_name': 'Всего прекращено заявлений',
                    'unit': 'Единица'},
                {'id': 7,
                    'name': 'Количество заявлений, по которым прекращено предоставление государственной услуги при замещении вакантной должности по содействию центра занятости населения',
                    'short_name': 'Прекращено заявлений при замещении вакантной должности по содействию ЦЗН',
                    'unit': 'Единица'},
                {'id': 8,
                    'name': 'Количество заявлений, по которым направлены работодателю уведомления с перечнем подобранных кандидатур работников с момента поступления задачи сотруднику центра занятости населения',
                    'short_name': 'Заявления с направленным перечнем подобранных кандидатур',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество заявлений, по которым было принято решение о прекращении предоставления государственной услуги при длительном отсутствии взаимодействия работодателя с центром занятости населения',
                    'short_name': 'Прекращенные заявления при длительном отсутствии контакта с ЦЗН',
                    'unit': 'Единица'},
                {'id': 10,
                    'name': 'Количество заявлений, по которым государственная услуга прекращена в связи с отказом работодателя от посредничества центра занятости населения',
                    'short_name': 'Прекращенные заявления из-за отказа от услуг ЦЗН',
                    'unit': 'Единица'}]},
        {'id': 3.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации профессиональной ориентации граждан в целях выбора сферы деятельности (профессии), трудоустройства, прохождения ПО и получения ДПО',
            'indicators': [{'id': 1,
                'name': 'Доля заявлений, поданных гражданами в результате согласия с предложением центра занятости населения об оказании государственной услуги, от общего количества заявлений',
                'short_name': 'Доля заявлений, поданных по предложению от ЦЗН (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля заявлений, поданных гражданами после реализации сервиса «Самостоятельное тестирование», от общего количества заявлений',
                    'short_name': 'Доля заявлений по сервису «Самостоятельное тестирование» (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Средний срок назначения даты личной явки для проведения предварительной беседы',
                    'short_name': 'Средний срок назначения личной явки предварительной беседы (03)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 4,
                    'name': 'Средний срок подбора и назначения тестов',
                    'short_name': 'Средний срок подбора и назначения тестов (04)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 5,
                    'name': 'Средний срок подбора и назначения тренинга',
                    'short_name': 'Средний срок подбора и назначения тренинга (05)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 6,
                    'name': 'Средний срок формирования заключения о предоставлении государственной услуги',
                    'short_name': 'Средний срок формирования заключения о предоставлении услуги (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Доля заявлений, по которым гражданину проведена профессиональная консультация, от общего количества заявлений, по которым гражданину направлено заключение о предоставлении государственной услуги',
                    'short_name': 'Доля заявлений с проведенной профессиональной консультацией от всех заключений (07)',
                    'unit': 'Процент'},
                {'id': 8,
                    'name': 'Среднее количество личных явок гражданина при получении государственной услуги',
                    'short_name': 'Среднее количество личных явок гражданина (08)',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество поданных заявлений о предоставлении государственной услуги профессиональной ориентации',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'},
                {'id': 10,
                    'name': 'Количество заявлений, по которым гражданину направлено заключение о предоставлении государственной услуги',
                    'short_name': 'Всего выдано заключений по услуге',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество заявлений, поданных гражданами в результате согласия с предложением центра занятости населения об оказании государственной услуги',
                    'short_name': 'Заявления поданные по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 12,
                    'name': 'Количество заявлений, поданных гражданами после реализации сервиса «Самостоятельное тестирование»',
                    'short_name': 'Всего заявлений по сервису «Самостоятельное тестирование»',
                    'unit': 'Единица'},
                {'id': 13,
                    'name': 'Количество заявлений, по которым гражданину проведена профессиональная консультация',
                    'short_name': 'Заявления с проведенной профессиональной консультацией',
                    'unit': 'Единица'}]},
        {'id': 4.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации ПО и ДПО безработных граждан, включая обучение в другой местности',
            'indicators': [{'id': 1,
                'name': 'Доля заявлений о предоставлении государственной услуги, поданных в результате согласия с предложением о предоставлении государственной услуги, от общего количества заявлений о предоставлении государственной услуги',
                'short_name': 'Доля заявлений по предложению от ЦЗН (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля заявлений о предоставлении государственной услуги, в рамках которых направлено предложение о предоставлении государственной услуги по профессиональной ориентации, от общего количества заявлений о предоставлении государственной услуги, в рамках которых проведена предварительная беседа',
                    'short_name': 'Доля заявлений с предложением профессиональной ориентации (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Средний срок подбора профессии (специальности)',
                    'short_name': 'Средний срок подбора профессии (03)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 4,
                    'name': 'Средний срок направления уведомления о проведении медицинского освидетельствования',
                    'short_name': 'Средний срок направления уведомлений о медицинском освидетельствовании (04)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 5,
                    'name': 'Средний срок подбора вариантов обучения',
                    'short_name': 'Средний срок подбора вариантов обучения (05)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 6,
                    'name': 'Средний срок направления уведомления о получении направления на обучение',
                    'short_name': 'Средний срок направления уведомления о получении направления на обучение (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Доля заявлений о предоставлении государственной услуги, по которым оказана финансовая поддержка для обучения в другой местности, от общего количества заявлений о предоставлении государственной услуги',
                    'short_name': 'Доля заявлений с возмещением затрат на обучение (07)',
                    'unit': 'Процент'},
                {'id': 8,
                    'name': 'Среднее количество личных явок при получении государственной услуги',
                    'short_name': 'Среднее количество личных явок (08)',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Доля граждан, трудоустроенных в течение 1,5 месяцев с даты окончания обучения, от общего количества граждан, прошедших обучение',
                    'short_name': 'Доля граждан трудоустроенных в течение 1,5 месяцев после обучения (09)',
                    'unit': 'Процент'},
                {'id': 10,
                    'name': 'Количество поданных заявлений о предоставлении государственной услуги ',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество заявлений о предоставлении государственной услуги, поданных гражданами в результате согласия с предложением центра занятости населения об оказании государственной услуги',
                    'short_name': 'Заявления по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 12,
                    'name': 'Количество заявлений о предоставлении государственной услуги, в рамках которых направлено предложение о предоставлении государственной услуги по профессиональной ориентации',
                    'short_name': 'Заявления с предложением профессиональной ориентации',
                    'unit': 'Единица'},
                {'id': 13,
                    'name': 'Количество заявлений, в рамках которых осуществлялся первый подбор профессии (специальности)',
                    'short_name': 'Заявления с рекомендуемым перечнем профессий',
                    'unit': 'Единица'},
                {'id': 14,
                    'name': 'Количество заявлений, по которым осуществлялось направление уведомления о проведении медицинского освидетельствования',
                    'short_name': 'Заявления с уведомлениями о медицинском освидетельствовании',
                    'unit': 'Единица'},
                {'id': 15,
                    'name': 'Количество заявлений, в рамках которых осуществлялся подбор вариантов обучения',
                    'short_name': 'Заявления с рекомендуемым перечнем образовательных программ',
                    'unit': 'Единица'},
                {'id': 16,
                    'name': 'Количество заявлений, по которым осуществлялось направление уведомления о получении направления на обучение',
                    'short_name': 'Заявления с уведомлениями о получении направления на обучение',
                    'unit': 'Единица'},
                {'id': 17,
                    'name': 'Количество заявлений о предоставлении государственной услуги, по которым оказана финансовая поддержка для обучения в другой местности',
                    'short_name': 'Заявления с возмещением затрат на обучение',
                    'unit': 'Единица'},
                {'id': 18,
                    'name': 'Количество граждан, которым оказана государственная услуга',
                    'short_name': 'Всего оказано услуг',
                    'unit': 'Единица'},
                {'id': 19,
                    'name': 'Количество граждан, прошедших обучение',
                    'short_name': 'Заявления с завершенным обучением',
                    'unit': 'Единица'},
                {'id': 20,
                    'name': 'Количество граждан, трудоустроенных в течение 1,5 месяцев с даты окончания обучения',
                    'short_name': 'Всего трудоустроено в течение 1,5 месяцев после обучения',
                    'unit': 'Единица'}]},
        {'id': 5.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению полномочий по организации ПО и ДПО женщин в период отпуска по уходу за ребенком до достижения им возраста трех лет, незанятых граждан, которым в соответствии с законодательством РФ назначена страховая пенсия по старости и которые стремятся возобновить трудовую деятельность',
            'indicators': [{'id': 1,
                'name': 'Доля заявлений на организацию профессионального обучения или дополнительного профессионального образования, поданных в результате согласия с предложением по организации профессионального обучения или дополнительного профессионального образования, от общего количества заявлений об организации профессионального обучения или дополнительного профессионального образования',
                'short_name': 'Доля заявлений по предложению от ЦЗН (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля заявлений об организации профессионального обучения или дополнительного профессионального образования, в рамках которых направлено предложение о предоставлении государственной услуги по профессиональной ориентации, от общего количества заявлений об организации профессионального обучения или дополнительного профессионального образования, в рамках которых проведена предварительная беседа',
                    'short_name': 'Доля заявлений с предложением профессиональной ориентации (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Средний срок подбора профессии (специальности)',
                    'short_name': 'Средний срок подбора профессии (03)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 4,
                    'name': 'Средний срок направления уведомления о проведении медицинского освидетельствования',
                    'short_name': 'Средний срок направления уведомлений о медицинском освидетельствовании (04)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 5,
                    'name': 'Средний срок подбора вариантов обучения',
                    'short_name': 'Средний срок подбора вариантов обучения (05)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 6,
                    'name': 'Средний срок направления уведомления о получении направления на обучение',
                    'short_name': 'Средний срок направления уведомления о получении направления на обучение (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Доля заявлений на организацию профессионального обучения или дополнительного профессионального образования, по которым оказана финансовая поддержка для обучения в другой местности, от общего количества заявлений',
                    'short_name': 'Доля заявлений с возмещением затрат на обучение (07)',
                    'unit': 'Процент'},
                {'id': 8,
                    'name': 'Среднее количество личных явок при осуществлении полномочия',
                    'short_name': 'Среднее количество личных явок при осуществлении полномочия (08)',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Доля граждан, трудоустроенных в течение 1,5 месяцев с даты окончания обучения, от общего количества граждан, прошедших обучение',
                    'short_name': 'Доля граждан трудоустроенных в течение 1,5 месяцев после обучения (09)',
                    'unit': 'Процент'},
                {'id': 10,
                    'name': 'Количество поданных заявлений на организацию профессионального обучения или дополнительного профессионального образования из числа пенсионеров, стремящихся возобновить трудовую деятельность',
                    'short_name': 'Всего подано заявлений пенсионерами',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество поданных заявлений на организацию профессионального обучения или дополнительного профессионального образования из числа женщин, находящихся в отпуске по уходу за ребенком до достижения им возраста 3-х лет',
                    'short_name': 'Всего подано заявлений женщинами',
                    'unit': 'Единица'},
                {'id': 12,
                    'name': 'Количество заявлений по организации профессионального обучения или дополнительного профессионального образования, поданных в результате согласия с предложением',
                    'short_name': 'Заявления по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 13,
                    'name': 'Количество заявлений на организацию профессионального обучения или дополнительного профессионального образования, в рамках которых направлено предложение о предоставлении государственной услуги по профессиональной ориентации',
                    'short_name': 'Заявления с предложением профессиональной ориентации',
                    'unit': 'Единица'},
                {'id': 14,
                    'name': 'Количество заявлений, в рамках которых осуществлялся первый подбор профессии (специальности)',
                    'short_name': 'Заявления с рекомендуемым перечнем профессий',
                    'unit': 'Единица'},
                {'id': 15,
                    'name': 'Количество заявлений, по которым осуществлялось направление уведомления о проведении медицинского освидетельствования',
                    'short_name': 'Заявления с уведомлениями о медицинском освидетельствовании',
                    'unit': 'Единица'},
                {'id': 16,
                    'name': 'Количество заявлений, в рамках которых осуществлялся подбор вариантов обучения',
                    'short_name': 'Заявления с подбором вариантов обучения',
                    'unit': 'Единица'},
                {'id': 17,
                    'name': 'Количество заявлений, по которым осуществлялось направление уведомления о получении направления на обучение',
                    'short_name': 'Заявления с уведомлениями о получении направления на обучение',
                    'unit': 'Единица'},
                {'id': 18,
                    'name': 'Количество заявлений на организацию профессионального обучения или дополнительного профессионального образования, по которым оказана финансовая поддержка для обучения в другой местности',
                    'short_name': 'Заявления с возмещением затрат на обучение',
                    'unit': 'Единица'},
                {'id': 19,
                    'name': 'Количество заявлений на организацию профессионального обучения или дополнительного профессионального образования, имеющих статус «Полномочие осуществлено»',
                    'short_name': 'Заявления, по которым оказано полномочие',
                    'unit': 'Единица'},
                {'id': 20,
                    'name': 'Количество граждан, прошедших обучение',
                    'short_name': 'Заявления с завершенным обучением',
                    'unit': 'Единица'},
                {'id': 21,
                    'name': 'Количество граждан, трудоустроенных в течение 1,5 месяцев с даты окончания обучения',
                    'short_name': 'Всего трудоустроено в течение 1,5 месяцев после обучения',
                    'unit': 'Единица'}]},
        {'id': 6.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению полномочий по организации и проведению специальных мероприятий по профилированию граждан, зарегистрированных в целях поиска подходящей работы, и работодателей',
            'indicators': [{'id': 1,
                'name': 'Доля граждан, прошедших анкетирование в установленный срок с даты постановки на регистрационный учет в целях поиска подходящей работы',
                'short_name': 'Доля вовремя прошедших анкетирование (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля граждан, не трудоустроенных в течение 3 месяцев с даты постановки на регистрационный учет в целях поиска подходящей работы, прошедших повторное анкетирование',
                    'short_name': 'Доля нетрудоустроенных за 3 месяца, прошедших повторное анкетирование (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Средний срок между формированием перечня рекомендуемых гражданину государственных услуг (сервисов), мероприятий в области содействия занятости населения на основе определенной для гражданина профильной группы и направлением индивидуального плана гражданину',
                    'short_name': 'Средний срок между рекомендацией услуг/мероприятий и направлением индивидуального плана гражданину (03)',
                    'unit': 'Дни'},
                {'id': 4,
                    'name': 'Средний срок между формированием перечня рекомендуемых работодателю государственных услуг (сервисов), мероприятий в области содействия занятости населения на основе определенной для работодателя профильной группы и направлением индивидуального плана работодателю',
                    'short_name': 'Средний срок между рекомендацией услуг/мероприятий и направлением индивидуального плана работодателю (04)',
                    'unit': 'Дни'},
                {'id': 5,
                    'name': 'Доля работодателей, прошедших анкетирование, от общего количества работодателей, обратившихся за получением государственной услуги содействия работодателям в подборе необходимых работников',
                    'short_name': 'Доля прошедших анкетирование от всех заявлений по подбору необходимых работников (05)',
                    'unit': 'Процент'},
                {'id': 6,
                    'name': 'Количество граждан, нетрудоустроенных в течение 3 месяцев с даты постановки на регистрационный учет в целях поиска подходящей работы, у которых истек срок, установленный для прохождения повторного анкетирования',
                    'short_name': 'Всего нетрудоустроенных за 3 месяца',
                    'unit': 'Единица'},
                {'id': 7,
                    'name': 'Количество работодателей, обратившихся за получением государственной услуги содействия работодателям в подборе необходимых работников',
                    'short_name': 'Заявлений от работодателей',
                    'unit': 'Единица'},
                {'id': 8,
                    'name': 'Количество работодателей, прошедших анкетирование',
                    'short_name': 'Количество работодателей, прошедших анкетирование',
                    'unit': 'Единица'}]},
        {'id': 7.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению полномочий по организации проведения оплачиваемых общественных работ',
            'indicators': [{'id': 1,
                'name': 'Доля предложений центра занятости населения об участии в общественных работах, которые привели к трудоустройству гражданина',
                'short_name': 'Доля трудоустроенных от предложений ЦЗН (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля охваченных предложением граждан, из числа граждан, для которых общественные работы являются подходящей работой',
                    'short_name': 'Доля предложений (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Доля трудоустроенных на общественные работы граждан, для которых общественные работы являются подходящей работой',
                    'short_name': 'Доля трудоустроенных (03)',
                    'unit': 'Процент'},
                {'id': 4,
                    'name': 'Отношение численности безработных граждан, трудоустроенных на общественные работы, к общей численности зарегистрированных в отчетном периоде безработных граждан',
                    'short_name': 'Доля трудоустроенных безработных (04)',
                    'unit': 'Процент'},
                {'id': 5,
                    'name': 'Средний срок направления гражданам уведомления с перечнем вариантов подходящей работы (вакансий) по общественным работам с момента поступления сведений о свободных рабочих местах и вакантных должностях на единую цифровую платформу',
                    'short_name': 'Средний срок направления перечня вакансий с поступления сведений о вакантных должностях (05)',
                    'unit': 'Рабочие дни',
                    'norm': 10.0},
                {'id': 6,
                    'name': 'Средний срок направления гражданам уведомления о проведении переговоров по общественным работам с работодателем с момента получения от заявителя ранжированного перечня вакансий',
                    'short_name': 'Средний срок переговоров с работодателем с получения ранжированного перечня вакансий (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Доля вакансий на общественные работы от общего числа размещенных на единой цифровой платформе вакансий',
                    'short_name': 'Доля вакансий по услуге от общего числа (07)',
                    'unit': 'Процент'},
                {'id': 8,
                    'name': 'Количество поданных заявлений о предоставлении государственной услуги',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество граждан, трудоустроенных на общественные работы',
                    'short_name': 'Всего трудоустроено',
                    'unit': 'Единица'},
                {'id': 10,
                    'name': 'Количество безработных граждан, трудоустроенных на общественные работы',
                    'short_name': 'Трудоустроено безработных',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество граждан, трудоустроенных на общественные работы по предложениям центра занятости населения',
                    'short_name': 'Трудоустроено по предложению от ЦЗН',
                    'unit': 'Единица'}]},
        {'id': 8.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации временного трудоустройства несовершеннолетних граждан в возрасте от 14 до 18 лет в свободное от учебы время, безработных граждан, испытывающих трудности в поиске работы, безработных граждан в возрасте от 18 до 25 лет, имеющих среднее профессиональное образование или высшее образование и ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации',
            'indicators': [{'id': 1,
                'name': 'Доля принятых гражданами предложений об участии во временном трудоустройстве',
                'short_name': 'Доля принятых предложений (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля граждан, принятых на работы временного характера, от общего числа граждан, подавших заявления о предоставлении государственной услуги',
                    'short_name': 'Доля трудоустроенных (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Доля охваченных предложением об участии во временном трудоустройстве граждан, из числа граждан, для которых временные работы являются подходящей работой',
                    'short_name': 'Доля получивших предложение от общего числа (03)',
                    'unit': 'Процент'},
                {'id': 4,
                    'name': 'Доля граждан, принятых на работы временного характера, для которых временные работы являются подходящей работой (без учета несовершеннолетних) от общего числа граждан, принятых на работы временного характера',
                    'short_name': 'Доля трудоустроенных совершеннолетних от общего числа (04)',
                    'unit': 'Процент'},
                {'id': 5,
                    'name': 'Доля несовершеннолетних граждан от 14 до 18 лет, которые были приняты на работы временного характера, от общей численности несовершеннолетних граждан в возрасте от 14 до 18 лет в субъекте Российской Федерации',
                    'short_name': 'Доля трудоустроенных несовершеннолетних от всех несовершеннолетних в РФ (05)',
                    'unit': 'Процент'},
                {'id': 6,
                    'name': 'Средний срок направления гражданам уведомления о проведении переговоров о временном трудоустройстве с работодателем с момента получения от заявителя ранжированного перечня вакансий',
                    'short_name': 'Средний срок между проведением переговоров и получения ранжированного перечня вакансий (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Доля отказов гражданам при подаче заявления в рамках групповых заявок',
                    'short_name': 'Доля отказов при подаче заявлений в рамках групповых заявок (07)',
                    'unit': 'Процент'},
                {'id': 8,
                    'name': 'Количество поданных заявлений несовершеннолетних граждан от 14 до 18 лет о предоставлении государственной услуги',
                    'short_name': 'Заявлений от несовершеннолетних',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество несовершеннолетних граждан от 14 до 18 лет, трудоустроенных на временные работы',
                    'short_name': 'Трудоустроено несовершеннолетних',
                    'unit': 'Единица'},
                {'id': 10,
                    'name': 'Количество несовершеннолетних граждан от 14 до 18 лет, трудоустроенных на временные работы по предложениям центра занятости населения',
                    'short_name': 'Трудоустроено несовершеннолетних по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество поданных заявлений безработных граждан, испытывающих трудности в поиске работы о предоставлении государственной услуги',
                    'short_name': 'Заявлений от безработных',
                    'unit': 'Единица'},
                {'id': 12,
                    'name': 'Количество безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы',
                    'short_name': 'Трудоустроено безработных',
                    'unit': 'Единица'},
                {'id': 13,
                    'name': 'Количество безработных граждан, испытывающих трудности в поиске работы, трудоустроенных на временные работы по предложениям центра занятости населения',
                    'short_name': 'Трудоустроено безработных по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 14,
                    'name': 'Количество поданных заявлений безработных граждан в возрасте от 18 до 25 лет о предоставлении государственной услуги',
                    'short_name': 'Заявлений от граждан 18-25 лет',
                    'unit': 'Единица'},
                {'id': 15,
                    'name': 'Количество безработных граждан в возрасте от 18 до 25 лет, трудоустроенных на временные работы',
                    'short_name': 'Трудоустроено граждан 18-25 лет',
                    'unit': 'Единица'},
                {'id': 16,
                    'name': 'Количество безработных граждан в возрасте от 18 до 25 лет, трудоустроенных на временные работы по предложениям центра занятости населения',
                    'short_name': 'Трудоустроено граждан 18-25 лет по предложению от ЦЗН',
                    'unit': 'Единица'}]},
        {'id': 9.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по организации сопровождения при содействии занятости инвалидов',
            'indicators': [{'id': 1,
                'name': 'Средний срок принятия решения о предоставлении инвалиду государственной услуги центром занятости населения самостоятельно',
                'short_name': 'Средний срок принятия решения по услугам без участия МСЭ (01)',
                'unit': 'Дни',
                'norm': 10.0},
                {'id': 2,
                    'name': 'Средний срок принятия решения о предоставлении инвалиду государственной услуги центром занятости населения при участии учреждения МСЭ',
                    'short_name': 'Средний срок принятия решения по услугам с участием МСЭ (02)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 3,
                    'name': 'Доля заявлений, по которым был направлен запрос в учреждение МСЭ для определения нуждаемости инвалида в сопровождении при содействии занятости',
                    'short_name': 'Доля заявлений с участием МСЭ (03)',
                    'unit': 'Процент'},
                {'id': 4,
                    'name': 'Средний срок формирования центром занятости населения индивидуального плана',
                    'short_name': 'Средний срок формирования индивидуального плана (04)',
                    'unit': 'Дни'},
                {'id': 5,
                    'name': 'Средняя продолжительность поиска работы гражданами, получающими государственную услугу',
                    'short_name': 'Средняя продолжительность поиска работы (05)',
                    'unit': 'Дни'},
                {'id': 6,
                    'name': 'Доля граждан, получающих государственную услугу, не трудоустроенных в течение шести месяцев со дня принятия решения о нуждаемости инвалида в сопровождении при содействии занятости от общего количества граждан, получающих государственную услугу',
                    'short_name': 'Доля нетрудоустроенных за 6 месяцев (06)',
                    'unit': 'Процент'},
                {'id': 7,
                    'name': 'Количество поданных заявлений граждан о предоставлении государственной услуги',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'},
                {'id': 8,
                    'name': 'Количество заявлений, по которым принято решение о предоставлении государственной услуги и не отправлялся запрос в учреждения МСЭ',
                    'short_name': 'Оказано услуг без участия МСЭ',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество заявлений, по которым принято решение о предоставлении государственной услуги и направлялся запрос в учреждения МСЭ',
                    'short_name': 'Оказано услуг с участием МСЭ',
                    'unit': 'Единица'}]},
        {'id': 10.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по социальной адаптации безработных граждан на рынке труда',
            'indicators': [{'id': 1,
                'name': 'Доля граждан, получивших государственную услугу, от общего числа граждан, признанных в отчетном периоде безработными',
                'short_name': 'Доля оказанных услуг (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля заявлений, поданных гражданами в результате согласия с предложением центра занятости населения (далее - ЦЗН) об оказании государственной услуги, от общего количества заявлений',
                    'short_name': 'Доля заявлений по предложению от ЦЗН (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Средний срок подбора и назначения тестов',
                    'short_name': 'Средний срок подбора и назначения тестов (03)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 4,
                    'name': 'Средний срок формирования плана реализации сервисов (мероприятий)',
                    'short_name': 'Средний срок формирования плана реализации сервисов (мероприятий) (04)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 5,
                    'name': 'Средний срок обработки результатов получения сервисов',
                    'short_name': 'Средний срок обработки результатов получения сервисов (05)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 6,
                    'name': 'Средний срок формирования заключения о предоставлении государственной услуги',
                    'short_name': 'Средний срок формирования заключения (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Доля заявлений, по которым гражданину проведена индивидуальная консультация, от общего количества заявлений, по которым гражданину направлено заключение о предоставлении государственной услуги',
                    'short_name': 'Доля индивидуальных консультаций от общего количества заявлений (07)',
                    'unit': 'Процент'},
                {'id': 8,
                    'name': 'Среднее количество личных явок гражданина при получении государственной услуги',
                    'short_name': 'Среднее количество личных явок (08)',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество поданных заявлений граждан о предоставлении услуги по социальной адаптации',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'},
                {'id': 10,
                    'name': 'Количество заявлений, по которым гражданину направлено заключение о предоставлении государственной услуги',
                    'short_name': 'Оказано услуг',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество заявлений, поданных гражданами в результате согласия с предложением цента занятости населения',
                    'short_name': 'Заявления по предложению от ЦЗН',
                    'unit': 'Единица'}]},
        {'id': 11.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по психологической поддержке безработных граждан',
            'indicators': [{'id': 1,
                'name': 'Доля граждан, получивших государственную услугу, в численности зарегистрированных в отчетном периоде безработных граждан',
                'short_name': 'Доля оказанных услуг (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля заявлений, поданных гражданами в результате согласия с предложением центра занятости населения об оказании государственной услуги, от общего количества заявлений',
                    'short_name': 'Доля заявлений по предложению от ЦЗН (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Средний срок подбора и назначения тестов',
                    'short_name': 'Средний срок подбора и назначения тестов (03)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 4,
                    'name': 'Средний срок формирования плана реализации сервисов (мероприятий)',
                    'short_name': 'Средний срок формирования плана реализации сервисов (мероприятий) (04)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 5,
                    'name': 'Средний срок обработки результатов получения сервисов',
                    'short_name': 'Средний срок обработки результатов получения сервисов (05)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 6,
                    'name': 'Средний срок формирования заключения о предоставлении государственной услуги',
                    'short_name': 'Средний срок формирования заключения (06)',
                    'unit': 'Дни',
                    'norm': 10.0},
                {'id': 7,
                    'name': 'Среднее количество личных явок гражданина при получении государственной услуги',
                    'short_name': 'Среднее количество личных явок (07)',
                    'unit': 'Единица'},
                {'id': 8,
                    'name': 'Доля тестов, пройденных дистанционно на единой цифровой платформе, в общем количестве тестов, пройденных при предоставлении государственной услуги',
                    'short_name': 'Доля пройденных дистанционных тестов (08)',
                    'unit': 'Процент'},
                {'id': 9,
                    'name': 'Доля мероприятий в составе сервисов, предоставленных дистанционно, в общем количестве мероприятий в рамках сервисов, предоставленных в рамках государственной услуги',
                    'short_name': 'Доля дистанционных мероприятий (09)',
                    'unit': 'Процент'},
                {'id': 10,
                    'name': 'Количество заявлений, поданных гражданами в результате согласия с предложением цента занятости населения',
                    'short_name': 'Заявления по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 11,
                    'name': 'Количество поданных заявлений граждан о предоставлении услуги психологической поддержки',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'},
                {'id': 12,
                    'name': 'Количество заявлений, по которым гражданину направлено заключение о предоставлении государственной услуги',
                    'short_name': 'Оказано услуг',
                    'unit': 'Единица'}]},
        {'id': 12.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по содействию безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости',
            'indicators': [{'id': 1,
                'name': 'Доля трудоустроенных безработных граждан или граждан, зарегистрированных в органах службы занятости в целях поиска подходящей работы, из числа безработных граждан или граждан, зарегистрированных в органах службы занятости в целях поиска подходящей работы, обратившихся за (согласившихся на) предоставлением государственной услуги по содействию безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, в переезде и безработным гражданам и гражданам, зарегистрированным в органах службы занятости в целях поиска подходящей работы, и членам их семей в переселении в другую местность для трудоустройства по направлению органов службы занятости',
                'short_name': 'Доля трудоустроенных (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Количество поданных заявлений граждан о предоставлении услуги по содействию в переезде и переселении',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'}]},
        {'id': 13.0,
            'name': 'Отчет по показателям исполнения стандарта деятельности по осуществлению государственной услуги по содействию осуществления предпринимательской деятельности безработных граждан, включая оказание гражданам, признанным в установленном порядке безработными, и гражданам, признанным в установленном порядке безработными и прошедшим профессиональное обучение или получившим дополнительное профессиональное образование',
            'indicators': [{'id': 1,
                'name': 'Доля заявлений о предоставлении государственной услуги, поданных в результате согласия с предложением о предоставлении государственной услуги, от общего количества заявлений о предоставлении государственной услуги',
                'short_name': 'Доля заявлений по предложению от ЦЗН (01)',
                'unit': 'Процент'},
                {'id': 2,
                    'name': 'Доля граждан, получивших услугу, осуществивших государственную регистрацию в качестве предпринимателя, государственную регистрацию создаваемого юридического лица, государственную регистрацию крестьянского (фермерского) хозяйства, постановку на учет в качестве налогоплательщика налога на профессиональный доход и осуществляющих указанную деятельность 12 и более месяцев, от общего числа граждан, осуществивших государственную регистрацию в качестве предпринимателя, государственную регистрацию создаваемого юридического лица, государственную регистрацию крестьянского (фермерского) хозяйства, постановку на учет в качестве налогоплательщика налога на профессиональный доход в рамках получения государственной услуги',
                    'short_name': 'Доля граждан осуществляющих деятельность 12 и более месяцев (02)',
                    'unit': 'Процент'},
                {'id': 3,
                    'name': 'Доля граждан, получивших одобрение бизнес-плана комиссией (рабочей группой)',
                    'short_name': 'Доля граждан, получивших одобрение бизнес-плана комиссией (03)',
                    'unit': 'Процент'},
                {'id': 4,
                    'name': 'Доля граждан, получивших единовременную финансовую помощь при государственной регистрации в рамках предоставления государственной услуги',
                    'short_name': 'Доля заявления с оказанной финансовой помощью (04)',
                    'unit': 'Процент'},
                {'id': 5,
                    'name': 'Средний срок предоставления государственной услуги',
                    'short_name': 'Средний срок оказания услуги (05)',
                    'unit': 'Дни'},
                {'id': 6,
                    'name': 'Количество заявлений, поданных гражданами в результате согласия с предложением центра занятости населения об оказании государственной услуги',
                    'short_name': 'Заявления по предложению от ЦЗН',
                    'unit': 'Единица'},
                {'id': 7,
                    'name': 'Количество заявлений, по которым завершено оказание государственных услуг',
                    'short_name': 'Оказано услуг',
                    'unit': 'Единица'},
                {'id': 8,
                    'name': 'Количество заявлений, по которым бизнес-планы рассмотрены комиссией (рабочей группой)',
                    'short_name': 'Заявления, по которым бизнес-планы рассмотрены комиссией',
                    'unit': 'Единица'},
                {'id': 9,
                    'name': 'Количество изданных приказов об оказании гражданам единовременной финансовой помощи',
                    'short_name': 'Заявления с оказанной финансовой помощью',
                    'unit': 'Единица'},
                {'id': 10,
                    'name': 'Количество поданных заявлений о предоставлении государственной услуги',
                    'short_name': 'Всего подано заявлений',
                    'unit': 'Единица'}]}]}
