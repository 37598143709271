<template>
  <div>

    <table v-if="data">
      <tr>
        <th v-for="(headerCell, headerCellIndex) in data.headerCells" :key="'cell'+headerCellIndex">
          {{truncateStr(headerCell.caption)}}
        </th>
      </tr>
      <tr v-for="(row, rowIndex) in data.rows" :key="'row'+rowIndex">
        <td v-for="(rowCell, rowCellIndex) in row" :key="'row_'+rowIndex + '_cell_' + rowCellIndex">
          {{rowCell.cellDataType === 'DECIMAL' ? Math.floor(rowCell.value*100)/100 : rowCell.cellDataType === 'PERCENT' ? Math.floor(rowCell.value*100) + '%' : rowCell.value}}
        </td>
      </tr>
    </table>
    <v-progress-linear
        v-else
        indeterminate
        color="primary"
    ></v-progress-linear>
  </div>
</template>

<script>

export default {
  name: 'ResultMonitorReportContent',
  components: {},
  props: ['period', 'regionAndDepartmentInfo', 'reportDataFunction'],

  data: () => ({
    data: undefined
  }),
  methods: {
    truncateStr(str, n = 30) {
      if (!str) return str
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str;
    },
    async init(){
      this.data = undefined
      this.data = await this.reportDataFunction(this.period, this.regionAndDepartmentInfo)
    }
  },
  watch: {
    regionAndDepartmentInfo: {
      async handler() {
        await this.init()
      },
      deep: true
    },
    period: {
      async handler() {
        await this.init()
      }
    }
  },
  async beforeMount() {
    await this.init()
  }
}
</script>

<style>
</style>
