<template>
  <v-expansion-panels>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="report-list__item">
          <a class="report-list__title">
            <button class="toggleSwitch">
              <svg class="closed" fill="none" height="24" viewBox="0 0 24 24"
                   width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M12 14.5L17 9.5L7 9.5L12 14.5Z" fill="#1551D0"/>
              </svg>
              <svg class="opened" fill="none" height="24" viewBox="0 0 24 24"
                   width="24"
                   xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5 12L9.5 7L9.5 17L14.5 12Z" fill="#1551D0"/>
              </svg>
            </button>
            <span>{{title}}</span>
          </a>
          <div class="report-list__download" @click.stop="download()">
          </div>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <ResultMonitorReportContent
            :period="period"
            :report-data-function="reportDataFunction"
            :region-and-department-info="regionAndDepartmentInfo"></ResultMonitorReportContent>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <LoaderDialog :show="showLoaderDialog"></LoaderDialog>
  </v-expansion-panels>
</template>

<script>

import ResultMonitorReportContent from "@/components/resultmonitor_new/ResultMonitorReportContent";
import LoaderDialog from "@/components/elements/LoaderDialog";
export default {
  name: 'ResultMonitorReport',
  components: {ResultMonitorReportContent, LoaderDialog},
  props: ['period', 'regionAndDepartmentInfo', 'title', 'reportDataFunction'],

  data: () => ({
    showLoaderDialog: false
  }),
  methods: {
    async download(){
      this.showLoaderDialog = true
      let data = await this.reportDataFunction(this.period, this.regionAndDepartmentInfo)
      await this.$downloadApi("Отчет.xlsx","/export/xlsx", data, true)
      this.showLoaderDialog = false
    }
  },
  beforeMount() {
  }
}
</script>

