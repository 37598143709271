import {processMonitorConfig} from "@/modules/ProcessMonitorConfig";

let departmentsByRegion
export function setDepartmentsByRegion(val){ //нужно чтобы передать в модуль перечень субъектов рф с цзн
    departmentsByRegion = JSON.parse(JSON.stringify(val))
}

export async function getAllValuesByPeriod(period){
    let values = []
    let paramsJson = localStorage.getItem('processMonreportItems')
    if (paramsJson) {
        values = JSON.parse(paramsJson)
    }
    return values.filter(e=>e.period===period)
}

export async function getNormativeReport(reportId, period){
    let values = await getAllValuesByPeriod(period)
    let reportConfig = processMonitorConfig.reports.find(e=>e.id===reportId)
    let indicatorsWithNorm = reportConfig.indicators.filter(e=>e.norm != null)
    let reportData = {
        headerCells: [
            {
                caption: "Субъект РФ",
                width: 5000,
            }
        ],
        rows: []
    }
    for(let indicator of indicatorsWithNorm){
        reportData.headerCells.push({
            caption: indicator.name + ': значение',
            width: 5000,
        })
        reportData.headerCells.push({
            caption: indicator.name + ': норматив',
            width: 5000
        })
        reportData.headerCells.push({
            caption: indicator.name + ': соответствие нормативу',
            width: 5000
        })
    }

    for(let region of departmentsByRegion){
        let regionValues = values.filter(e=>e.regionCode===region.code)
        let row = [{cellDataType: "STRING", value: region.name}]
        for(let indicator of indicatorsWithNorm){
            let v = regionValues.find(e => e.reportId === reportId && e.indicatorId === indicator.id)
            row.push({
                cellDataType: 'DECIMAL',
                value: v?.value
            })
            row.push({
                cellDataType: 'DECIMAL',
                value: indicator.norm
            })
            row.push({
                cellDataType: 'PERCENT',
                value: v?.value/indicator.norm
            })
        }
        reportData.rows.push(row)
    }
    return reportData
}

export async function getSvodReport(reportId, period){
    let values = await getAllValuesByPeriod(period)
    let reportConfig = processMonitorConfig.reports.find(e=>e.id===reportId)

    let reportData = {
        headerCells: [
            {
                caption: "Субъект РФ",
                width: 5000,
            }
        ],
        rows: []
    }

    for(let indicator of reportConfig.indicators){
        reportData.headerCells.push({
            caption: indicator.name,
            width: 5000,
        })
    }

    for(let region of departmentsByRegion){
        let regionValues = values.filter(e=>e.regionCode===region.code)
        let row = [{cellDataType: "STRING", value: region.name}]
        for(let indicator of reportConfig.indicators){
            let v = regionValues.find(e => e.reportId === reportId && e.indicatorId === indicator.id)
            row.push({
                cellDataType: 'DECIMAL',
                value: v?.value
            })
        }
        reportData.rows.push(row)
    }

    return reportData
}

export async function getRatingByReport(reportId, period){
    let values = await getAllValuesByPeriod(period)
    let reportConfig = processMonitorConfig.reports.find(e=>e.id===reportId)
    let indicatorsWithNorm = reportConfig.indicators.filter(e=>e.norm != null)
    for(let region of departmentsByRegion) {
        let regionValues = values.filter(e => e.regionCode === region.code)
        let vSum = 0
        if(indicatorsWithNorm.length > 0){
            for(let indicator of indicatorsWithNorm) {
                let v = regionValues.find(e => e.reportId === reportId && e.indicatorId === indicator.id)
                if(v != null){
                    vSum += v.value/indicator.norm
                }
            }
            vSum /= indicatorsWithNorm.length

            region['v'+reportId] = vSum
        }
    }

    return normalizeRating(departmentsByRegion.map(e=>[
        e.name, e['v'+reportId]
    ]), 'П')
}

export async function getOverallRating(period){
    return combineRatings(
        [await getRatingByReport(1, period)],
        [1]
    )
}

function combineRatings(ratings, weights){
    if(ratings.length !== weights.length) throw 'ratings.length !== weights.length'
    let resObj = {}
    let rating_i = 0
    for(let rating of ratings){
        for(let el of rating){
            let existingValue = resObj[el[0]] || 0
            resObj[el[0]] = existingValue + weights[rating_i]*el[1]
        }
        rating_i++
    }
    let resArr = []
    for(let key in resObj){
        resArr.push([key, resObj[key]])
    }
    return normalizeRating(resArr, 'П')
}


function normalizeRating(rating, type){
    let min = findMinValue(rating)
    let max = findMaxValue(rating)
    if(max === min) {
        max = 1
        min = 0
    }
    if(type==='П') {
        rating = rating.map(e => [e[0], (e[1] - min) / (max-min) * 100])
    } else if(type==='О'){
        rating = rating.map(e => [e[0], (max - e[1]) / (max-min) * 100])
    } else {
        console.error('Unknown rating normalization type ', type)
        return undefined
    }

    rating.sort((a,b)=>a[1]<=b[1]?1:-1)
    return rating
}

//value is in element[1] place
function findMinValue(arr){
    let curMin = 1e12
    for(let el of arr){
        if(el[1] < curMin){
            curMin = el[1]
        }
    }
    return curMin
}

//value is in element[1] place
function findMaxValue(arr){
    let curMax = -1e12
    for(let el of arr){
        if(el[1] > curMax){
            curMax = el[1]
        }
    }
    return curMax
}

export const processMonitorRatings = [
    {
        id: 10,
        title: "Рейтинг по исполнению стандартов процессов осуществления органами службы занятости полномочий в области содействия занятости",
        dataFunction: getOverallRating
    },
    {
        id: 20,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги содействия гражданам в поиске подходящей работы",
        dataFunction: getRatingByReport.bind(null, 1)
    },
    {
        id: 30,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги содействия работодателям в подборе необходимых работников",
        dataFunction: getRatingByReport.bind(null, 2)
    },
    {
        id: 40,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги по организации профессиональной ориентации граждан в целях выбора сферы деятельности",
        dataFunction: getRatingByReport.bind(null, 3)
    },
    {
        id: 50,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги по организации ПО и ДПО безработных граждан, включая обучение в другой местности",
        dataFunction: getRatingByReport.bind(null, 4)
    },
    {
        id: 60,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении полномочий по организации ПО и ДПО женщин в период отпуска по уходу за ребенком до достижения им возраста трех лет, незанятых граждан, которым в соответствии с законодательством РФ назначена страховая пенсия по старости и которые стремятся возобновить трудовую деятельность",
        dataFunction: getRatingByReport.bind(null, 5)
    },
    {
        id: 80,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении полномочий по организации проведения оплачиваемых общественных работ",
        dataFunction: getRatingByReport.bind(null, 7)
    },
    {
        id: 90,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги по организации временного трудоустройства несовершеннолетних граждан в возрасте от 14 до 18 лет в свободное от учебы время, безработных граждан, испытывающих трудности в поиске работы, безработных граждан в возрасте от 18 до 25 лет, имеющих среднее профессиональное образование или высшее образование и ищущих работу в течение года с даты выдачи им документа об образовании и о квалификации",
        dataFunction: getRatingByReport.bind(null, 8)
    },
    {
        id: 100,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги по организации сопровождения при содействии занятости инвалидов",
        dataFunction: getRatingByReport.bind(null, 9)
    },
    {
        id: 110,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении государственной услуги по социальной адаптации безработных граждан на рынке труда",
        dataFunction: getRatingByReport.bind(null, 10)
    },
    {
        id: 120,
        title: "Рейтинг по уровню своевременности выполнения административных процедур (действий) при осуществлении  государственной услуги по психологической поддержке безработных граждан",
        dataFunction: getRatingByReport.bind(null, 11)
    }
]
