<template>
  <v-card class="docs">
    <v-card-title class="two-columns">
      <div class="text-h5 text-center">{{ group }}</div>
      <v-btn class="btn btn-outline" text
             @click="$refs['fileupload'].click()"
      >Импорт
      </v-btn>
    </v-card-title>
    <input type="file" multiple ref="fileupload"
           style="display:none"
           @change="importFile($event)">
    <div class="list">
      <table>
        <thead>
        <tr>
          <th>Краткое название показателя</th>
          <th>Значение</th>
          <th>Размерность</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(reportItem, indexItem) in reportItems"
            :key="'indexItem' + indexItem">
          <td>
            {{ reportItem.short_name }}
          </td>
          <td>
            <v-text-field
                :value="getValue(reportId, reportItem.id)?.value"
                @input="setValue(reportId, reportItem.id, $event)"
                type="number"></v-text-field>
          </td>
          <td>
            {{ reportItem.unit }}
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
          class="btn btn-outline"
          text
          @click="$emit('close')"
      >
        Отмена
      </v-btn>
      <v-btn
          v-if="$user.role==='ROLE_ADMIN' || $user.role==='ROLE_VNII'"
          class="btn btn-primary"
          @click="$emit('edit')"
      >
        Сохранить изменения
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
// import api from "@/modules/api";

import readXlsxFile from "read-excel-file";

export default {
  name: 'ProcessMonitorParamsDialog',
  props: ['regionAndDepartmentInfo', 'reportId', 'group', 'reportItems', 'values', 'period'],

  data: () => ({}),
  methods: {
    truncateStr(str, n) {
      if (str.length > n) {
        return str.substring(0, n - 3) + "..." + str.substring(str.length - 6, str.length)
      }
      return str
    },

    async importFile(event) {
      let rows = await readXlsxFile(event.target.files[0])
      console.log(rows)
      let row = rows[7]
      for (let i = 0; i < 12; i++) {
        this.setValue(1, i+1, row[i + 2])
      }
      console.log(this.values)
    },

    getValue(reportId, indicatorId) {
      return this.values.find(e => e.reportId === reportId && e.indicatorId === indicatorId && e.period === this.period
          && e.regionCode === this.regionAndDepartmentInfo.region.code
          && e.departmentId == this.regionAndDepartmentInfo.department?.id)
    },

    getValueOrCreateNew(reportId, indicatorId) {
      let value = this.getValue(reportId, indicatorId)
      if (!value) {
        value = {
          reportId,
          indicatorId,
          period: this.period,
          regionCode: this.regionAndDepartmentInfo.region.code,
          departmentId: this.regionAndDepartmentInfo.department?.id
        }
        this.values.push(value)
      }
      return value
    },

    setValue(reportId, indicatorId, val) {
      let value = this.getValueOrCreateNew(reportId, indicatorId)
      value.value = val
    }
  },
  filters: {},
  async beforeMount() {
    console.log('vals', this.values)
    console.log(this.period)
  }
}
</script>

<style lang="scss">
.list {
  padding: 0 24px !important;

  table {
    th {
      min-width: 20% !important;
    }

    th, td {
      border-bottom: 0 !important;
      padding: 5px 0 !important;
      padding-right: 10px !important;

      &:last-child {
        padding-right: 0 !important;
      }
    }
  }

  .v-text-field {
    margin-top: 0;
  }

  .v-text-field:not(.v-textarea):not(.v-file-input) .v-input__slot {
    height: initial;
    margin: 0;
    padding: 5px 5px;
  }
}

.v-dialog {
  .v-card.docs {
    .v-card__title.two-columns {
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>



